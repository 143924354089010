// Development:
// const root = "http://localhost:8000/api";
// Production:
const root = `${window.location.origin}/api`;
export const storeRecords = ({ images, type }) => {
  return fetch(`${root}/records`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
    },
    body: JSON.stringify({ images, type }),
  });
};
