import YouTube from "react-youtube";
import Webcam from "react-webcam";
import { useCallback, useEffect, useRef, useState } from "react";
import { storeRecords } from "../utitlities/requests";

const VideoPlayer = () => {
  const webcamRef = useRef(null);
  const [images, setImages] = useState([]);
  const [intervalId, setIntervalId] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [prevSpeedRate, setPrevSpeedRate] = useState(1);
  const [isCameraAvailable, setIsCameraAvailable] = useState(true);

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot({
        width: 256,
        height: 256,
      });
      setImages((prevImages) => [...prevImages, imageSrc]);
    }
  }, [webcamRef]);

  useEffect(() => {
    if (images.length >= 20) {
      storeRecords({
        images: images.slice(0, 10),
        type: "normal",
      });
      setImages((prevState) => [...prevState.slice(10)]);
    }
  }, [images]);

  useEffect(() => {
    if (isRunning && isCameraAvailable) {
      const II = setInterval(() => {
        capture();
      }, 1000);
      setIntervalId(II);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
  }, [isRunning, isCameraAvailable]);

  const onReady = (e) => {
    console.log("onReady was called", e);
  };

  const onPlay = (e) => {
    console.log("onPlay was called", intervalId, e);

    setIsRunning(true);
  };

  const onPause = (e) => {
    console.log("onPause was called", e);

    setIsRunning(false);
    setImages([]);
  };

  const onEnd = (e) => {
    console.log("onEnd was called", e);

    setIsRunning(false);
    setImages([]);
  };

  const onError = (e) => {
    console.log("onError was called", e);
  };

  const onPlaybackRateChange = ({ data }) => {
    console.log("onPlaybackRateChange was called", data);

    if (data > prevSpeedRate) {
      storeRecords({
        images: images.slice(Math.max(images.length - 10, 0)),
        type: "speedUp",
      });
    } else {
      storeRecords({
        images: images.slice(Math.max(images.length - 10, 0)),
        type: "speedDown",
      });
    }
    setImages([]);
    setPrevSpeedRate(data);
  };

  const opts = {
    height: "390",
    width: "640",
  };

  const videoConstraints = {
    width: 256,
    height: 256,
    facingMode: "user",
  };

  if (!isCameraAvailable) {
    return (
      <div className="text-xl text-red-600">Camera permission unavailable</div>
    );
  }

  return (
    <div className="flex justify-center items-center flex-col">
      <YouTube
        videoId={"n9m2inRpUiw"}
        onReady={onReady}
        onPlay={onPlay}
        onPause={onPause}
        onEnd={onEnd}
        onError={onError}
        onPlaybackRateChange={onPlaybackRateChange}
        className={"flex justify-center items-center mt-20"}
        opts={opts}
      />
      <div className="invisible">
        <Webcam
          ref={webcamRef}
          mirrored={true}
          width={256}
          height={256}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          onUserMediaError={() => {
            setIsCameraAvailable(false);
          }}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
